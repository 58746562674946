<template lang="pug">
  section.hero.is-medium
    .hero-body.is-relative
      slot
      p.has-text-secondary.text-main.is-size-6.mt-6.mb-1(
        v-if="topic"
      ) {{ topic }}
      h1.title.size-h1.mt-1.has-text-primary(v-html="title")
      h2.text-regular.subtitle.is-size-4.size-subtitle(
        v-if="subtitle"
      )
        | {{ subtitle }}

      .flex.valign.halign(
        v-if="!hideCta"
      )
        router-link.button.is-dark.mt-0.mr-2(
          to="/sign_up"
          ) Sign up for free

        button.button.is-primary.is-outlined(
          @click.prevent="showDemo"
          v-ga="'conversionWatchDemo'"
          ) Watch demo video

      transition(name="fade")
        a.scroll-link(
          :href='"#"+scrollAnchor'
          ref="scrollLink"
          v-if="showScrollLink"
        )
          svg.mouse(xmlns='http://www.w3.org/2000/svg' viewBox='0 0 76 130' preserveaspectratio='xMidYMid meet')
            g(fill='none' fill-rule='evenodd')
              rect(width='70' height='118' x='1.5' y='1.5' stroke='#7E69F6' stroke-width='3' rx='36')
              circle.scroll(cx='36.5' cy='31.5' r='6' fill='#7E69F6')

</template>

<script>
import showMouseScrollIfNecessary from '@/components/behaviors/ShowMouseScrollIfNecessary';

export default {
  name: "HeroSection",
  mixins: [showMouseScrollIfNecessary],
  props: [
    'topic',
    'title',
    'subtitle',
    'scrollAnchor',
    'hideCta'
  ],
  methods: {
    showDemo() {
      this.$mixpanel.track("FrontPage - Watch Demo");
      this.$buefy.modal.open({
        content: `<video width="900" height="506" controls autoplay>
                      <source src="/v2/uploads/demo-v3.mp4" type="video/mp4" />
                    </video>`,
        width: 900,
      })
    },
  }
}
</script>

<style lang="scss">
section.hero {
  svg,
  img {
    max-width: 770px;
    margin: auto;
    margin-bottom: 2rem;
  }
}
</style>
